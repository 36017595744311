/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-target-blank */
import React, {FC, Fragment, useMemo, useState} from 'react'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {IMenu} from '../../core/_menu-config'
import {AuthModel, useAuth} from '../../../../../app/modules/auth'
import LoopRenderer from 'src/app/widgets/molecule/loop-rendering'
import {ConditinalRendering} from 'src/app/widgets/molecule/conditional-rendering'
import IUserService from 'src/app/services/user/service'
import {SideBarMenuKabid} from './sidebar-menu-custom/kabid/Index'
import {
  getAllMenu,
  getBidang,
} from 'src/app/modules/applications/management-menu/menu-settings/core/request'
import {DataMenu, ParentMenu} from '../../core/request-menu/types/role-types'
import {useQuery} from 'react-query'
import Swal from 'sweetalert2'
import {isEmptyArray} from 'formik'

interface CheckGuardProtectionParams {
  guards: string[]
  subItems: ParentMenu[]
  bidang: string
  subBidang: string
  title: string
}

/**
 * checkGuardProtection.
 * @param {CheckGuardProtectionParams} param0
 * @param {AuthModel} auth
 * @returns {boolean}
 */

const checkGuardProtection = (
  {title, guards, bidang, subBidang, subItems}: CheckGuardProtectionParams,
  auth: AuthModel
): boolean => {
  const {role, nama_bidang, nama_sub_bidang} = auth.user

  const titleSubMenu = subItems?.map((item) => {
    return item.title
  })

  // Superadmin memiliki akses ke semua menu
  if (role === IUserService.ADMIN && !nama_bidang) {
    if (guards.includes(role) && !auth.user.uuid_bidang) {
      return true
    }
  }

  if (role === IUserService.SUPER_ADMIN && !nama_bidang) {
    if (guards.includes(role) && !auth.user.uuid_bidang) {
      return true
    }
  }

  if (
    role === IUserService.ADMIN ||
    role === IUserService.ADMIN_BPHTB ||
    role === IUserService.ADMIN_PBB ||
    role === IUserService.KABID ||
    role === IUserService.KASUBBID ||
    role === IUserService.KECAMATAN ||
    role === IUserService.KELURAHAN ||
    role === IUserService.NOTARIS ||
    role === IUserService.OPERATOR ||
    role === IUserService.PENATAAN_PBB ||
    role === IUserService.PETUGAS_LAPANGAN
  ) {
    if (title === 'Global Menu' && titleSubMenu.includes('Dashboard')) {
      return true
    }
  }

  if (guards.includes(role)) {
    if (
      bidang &&
      subBidang &&
      bidang.includes(nama_bidang) &&
      subBidang.includes(nama_sub_bidang)
    ) {
      return true
    }
  }

  if (role === IUserService.NOTARIS) {
    if (guards.includes(role) && bidang === null && subBidang === null) {
      return true
    }
  }

  return false
}

/**
 * Configurate navigate and setting show by guards
 *
 * @returns {React.ReactNode}
 */

//! Perbaiki Menu di Sini
const SidebarMenuMain: FC = () => {
  const {auth} = useAuth()
  const [menuArray, setMenuArray] = useState([])
  // let menu: IMenu[] = DefaultConfig
  let useGetMenu = () => {
    return useQuery({
      queryKey: ['Get All Menu'],
      queryFn: async () => {
        const res = await getAllMenu()
        return res.data
      },
      onError: (err) => {
        Swal.fire({
          title: 'Gagal',
          icon: 'error',
          text: String(err),
        })
      },
      refetchOnWindowFocus: false,
    })
  }

  const newMenu = useGetMenu()

  const menuCalculation = (data: any) => {
    let showRole: string = data
    if (data.role === 'kecamatan') {
      showRole = `${data} - ${data.nm_kecamatan}`
    }
    if (data.role === 'kelurahan') {
      showRole = `${data} - ${data.nm_kelurahan}`
    }

    if (data !== undefined) {
      showRole = `${auth.user.role}`
    }
    return `Dashboard \n (${showRole})`
  }

  switch (auth!.user!.role) {
    case IUserService.KABID:
      return <SideBarMenuKabid />
    default:
      return (
        <>
          <LoopRenderer data={newMenu.data}>
            {(item: DataMenu, index) => {
              return (
                <ConditinalRendering
                  conditional={item === undefined || isEmptyArray(item)}
                  onTrueComponent={<p className='text-white text-center'>Menu belum tersedia</p>}
                  onFalseComponent={
                    <Fragment key={crypto.randomUUID()}>
                      <ConditinalRendering
                        conditional={checkGuardProtection(
                          {
                            guards: item.roles,
                            subItems: item.subItems,
                            bidang: item.bidang,
                            subBidang: item.sub_bidang,
                            title: item.title,
                          },
                          auth
                        )}
                        onTrueComponent={
                          <Fragment>
                            {/* parent title */}
                            <ConditinalRendering
                              conditional={item.title !== undefined}
                              onTrueComponent={
                                <Fragment>
                                  {/* <div className='menu-item'>
                                  <div className='menu-content pt-8 pb-2'>
                                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                                      {item.title}
                                    </span>
                                  </div>
                                </div> */}
                                </Fragment>
                              }
                            />
                          </Fragment>
                        }
                      />
                      {/* First Menu */}
                      <ConditinalRendering
                        conditional={checkGuardProtection(
                          {
                            guards: item.roles,
                            subItems: item.subItems,
                            bidang: item.bidang,
                            subBidang: item.sub_bidang,
                            title: item.title,
                          },
                          auth
                        )}
                        onTrueComponent={
                          <LoopRenderer data={item.subItems}>
                            {(subMenu: ParentMenu, index) => {
                              return (
                                <Fragment key={index}>
                                  <ConditinalRendering
                                    conditional={
                                      subMenu !== null &&
                                      subMenu?.subItems?.length > 0 &&
                                      checkGuardProtection(
                                        {
                                          title: subMenu.title,
                                          guards: subMenu.roles,
                                          bidang: subMenu.bidang,
                                          subBidang: subMenu.sub_bidang,
                                          subItems: subMenu.subItems,
                                        },
                                        auth
                                      )
                                    }
                                    onTrueComponent={
                                      <SidebarMenuItemWithSub
                                        key={crypto.randomUUID()}
                                        to={subMenu.link}
                                        title={subMenu.title}
                                        fontIcon='duotune'
                                        icon={subMenu.icon}
                                        isVisible={false}
                                      >
                                        <LoopRenderer data={subMenu.subItems}>
                                          {(optionMenu: IMenu, _) => (
                                            <SidebarMenuItem
                                              key={crypto.randomUUID()}
                                              to={optionMenu.link ?? ''}
                                              title={optionMenu.title ?? ''}
                                              hasBullet={true}
                                            />
                                          )}
                                        </LoopRenderer>
                                      </SidebarMenuItemWithSub>
                                    }
                                    onFalseComponent={
                                      <SidebarMenuItem
                                        key={crypto.randomUUID()}
                                        to={subMenu.link}
                                        icon={subMenu.icon}
                                        title={
                                          subMenu.title === 'Dashboard'
                                            ? `${subMenu.title} \n ${auth.user.username}`
                                            : subMenu.title
                                        }
                                        fontIcon='duotune'
                                      />
                                    }
                                  />
                                </Fragment>
                              )
                            }}
                          </LoopRenderer>
                        }
                      />
                    </Fragment>
                  }
                />
              )
            }}
          </LoopRenderer>
          <SidebarMenuItem
            key={crypto.randomUUID()}
            to='sign-auth'
            icon='exit-left'
            title='Sign Out'
            fontIcon='duotune'
          />
        </>
      )
  }
}

export {SidebarMenuMain}
